  import React, { useState, useEffect } from "react";
  import { Pie } from 'react-chartjs-2';
  import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
  import ChartDataLabels from 'chartjs-plugin-datalabels';
  import { GameControllerOutline, CashOutline, TrophyOutline } from 'react-ionicons';
  import { loadStripe } from '@stripe/stripe-js';
  import { Elements } from '@stripe/react-stripe-js';
  import CS2NoBackground from "./cs2NoBackground.png";
  import CheckoutForm from './checkoutForm';
  import "./cashoutCup.css";

  // Initialize Stripe outside of the component to avoid re-creating on each render
  const stripePromise = loadStripe('pk_live_51PBydeIitOy6POD7I5OZ0HRyiem5lj1BeDsnqdVuiKGlLCZZeKhOocuYL8M9ErDpXzWCd9m1hUxPpt8jdhVQQnCo00d7OYIqkH');

  // Register required elements for Chart.js and the datalabels plugin
  ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

  const CashoutCup = () => {
    const [formData, setFormData] = useState({
      teamName: '',
      captainName: '',
      teamLink: '',
    });
    const [marginLeft, setMarginLeft] = useState("125px");
    const [error, setError] = useState(null);
    const [processing, setProcessing] = useState(false);
    const [paymentSuccess, setPaymentSuccess] = useState(false);

    const data = {
      labels: ['Tournament\'s Prize Pool', 'Finals Prize Pool', 'Organizer\'s Cut'],
      datasets: [
        {
          label: 'Prize Distribution',
          data: [70, 10, 20], // 70%, 10%, and 20%
          backgroundColor: [
            '#4CAF50', // Green for Tournament Prize Pool
            'purple', // Purple for Finals Prize Pool
            '#E96200', // Orange for Organizer's Cut
          ],
          borderColor: [
            '#000000',
            '#000000',
            '#000000',
          ],
          borderWidth: 1,
        },
      ],
    };

    const options = {
      responsive: true,
      plugins: {
        legend: {
          position: 'bottom',
        },
        datalabels: {
          color: 'gold', // White text for better contrast on colored slices
          formatter: (value, context) => {
            const label = context.chart.data.labels[context.dataIndex];
            const percentage = `${(value).toFixed(0)}%`;
            return `${percentage}\n${label}`;
          },
          font: {
            size: 11,
            weight: 'bold',
          },
          textAlign: 'center',
        },
      },
    };

    const ResponsiveIcon = ({ IconComponent, defaultMargin, mobileMargin }) => {
      const [marginLeft, setMarginLeft] = useState(defaultMargin);
    
      useEffect(() => {
        const handleResize = () => {
          if (window.innerWidth <= 768) {
            setMarginLeft(mobileMargin);
          } else {
            setMarginLeft(defaultMargin);
          }
        };
    
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
      }, [defaultMargin, mobileMargin]);
    
      return (
        <IconComponent color={'#E96200'} height="150px" width="150px" style={{ marginLeft }} />
      );
    };
    

    useEffect(() => {
      const handleResize = () => {
        if (window.innerWidth <= 768) {
          setMarginLeft("0px");
        } else {
          setMarginLeft("125px");
        }
      };

      // Set the initial state
      handleResize();

      // Add event listener
      window.addEventListener('resize', handleResize);

      // Clean up
      return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
      <Elements stripe={stripePromise}>
        <div>
          <div className="cashout-cup-header">
            <div className="cashout-cup-header-text-holder">
              <h1 className="cashout-cup-header-h1">CASHOUT CUP</h1>
              <h2 className="cashout-cup-header-h2">13.10 - 15.12</h2>
            </div>
            <div className="cashout-cup-header-image-holder">
              <img src={CS2NoBackground} alt="Cashout Cup Logo" className="cashout-cup-header-image" />
            </div>
          </div>

          <div className="how-it-works-holder">
            <h2 className="how-it-works-h2">How it works</h2>
            <div className="how-it-works-content-holder">
              <div className="how-it-works-individual-holder">
                <ResponsiveIcon IconComponent={GameControllerOutline} defaultMargin="125px" mobileMargin="2.6em"/>
                <h3 className="how-it-works-h3">Play in single day tournaments</h3>
              </div>
              <div className="how-it-works-individual-holder">
                <ResponsiveIcon IconComponent={CashOutline} defaultMargin="100px" mobileMargin="1.5em" />
                <h3 className="how-it-works-h3">Win points and cash prizes</h3>
              </div>
              <div className="how-it-works-individual-holder">
                <ResponsiveIcon IconComponent={TrophyOutline} defaultMargin="115px" mobileMargin="1.8em" />
                <h3 className="how-it-works-h3">Qualify for the Season Finals</h3>
              </div>
            </div>
          </div>

          <div className="fee-and-prizepool-holder">
            <h2 className="fee-and-prizepool-h2">Entry Fee and Prize Pool</h2>
            <p className="fee-and-prizepool-p">The entry fee per team per tournament is 250NOK. Here is how it will be distributed:</p>
            
            <div className="pie-holder">
              <Pie data={data} options={options} />
            </div>
          </div>

          <div className="participation-requirements-holder">
            <h2 className="participation-requirements-h2">Participation Requirements</h2>
            <div className="participation-requirements-content-holder">
              <div className="participation-requirements-individual-holder">
                <h3 className="requirements-h3">Gamer.no</h3>
                <p className="requirements-p">Account required</p>
              </div>
              <div className="participation-requirements-individual-holder">
                <h3 className="requirements-h3">16+</h3>
                <p className="requirements-p">Required age</p>
              </div>
              <div className="participation-requirements-individual-holder">
                <h3 className="requirements-h3">3 out of 5</h3>
                <p className="requirements-p">Players must be Norwegian Citizens</p>
              </div>
            </div>
          </div>

          <div className="points-system-holder">
            <div className="placement-table-container">
              <h2 className="placement-table-heading">Placement and Points Earned</h2>
              <table className="placement-points-table">
                <thead>
                  <tr>
                    <th>Placement</th>
                    <th>Points</th>
                  </tr>
                </thead>
                <tbody>
                  <tr><td>1st</td><td>50</td></tr>
                  <tr><td>2nd</td><td>37</td></tr>
                  <tr><td>3rd-4th</td><td>27</td></tr>
                  <tr><td>5th-8th</td><td>16</td></tr>
                  <tr><td>9th-16th</td><td>8</td></tr>
                  <tr><td>17th-32nd</td><td>4</td></tr>
                  <tr><td>33rd-64th</td><td>3</td></tr>
                  <tr><td>65th-128th</td><td>2</td></tr>
                  <tr><td>129th-256th</td><td>1</td></tr>
                </tbody>
              </table>
            </div>
            <h3 className="points-system-h3">The top 8 teams with the most points at the end of the season qualify for the finals.</h3>
            <div className="tournament-table-container">
            <h2 className="tournament-table-heading">Tournament Dates</h2>
            <table className="tournament-dates-table">
              <thead>
                <tr>
                  <th>Tournament</th>
                  <th>Date</th>
                </tr>
              </thead>
              <tbody>
                <tr><td>Cashout Cup #1</td><td>Oct 13</td></tr>
                <tr><td>Cashout Cup #2</td><td>Oct 27</td></tr>
                <tr><td>Cashout Cup #3</td><td>Nov 10</td></tr>
                <tr><td>Cashout Cup #4</td><td>Nov 24</td></tr>
                <tr><td>Cashout Cup #5</td><td>Dec 8</td></tr>
                <tr><td>Season Finals</td><td>Dec 15</td></tr>
              </tbody>
            </table>
          
            <h2 className="leaderboard-heading">Leaderboard</h2>
            <h3 className="leaderboard-h3">The current Cashout Cup finals prize pool is 225NOK</h3>
            <table className="leaderboard-table">
              <thead>
                <tr>
                  <th>Team</th>
                  <th>Points</th>
                </tr>
              </thead>
              <tbody>
                <tr><td>Bitfix Gaming</td><td>77</td></tr>
                <tr><td>Feis Klein</td><td>64</td></tr>
                <tr><td>Void</td><td>50</td></tr>
                <tr><td>Quadrivium</td><td>37</td></tr>
                
                <tr><td>Mosal</td><td>27</td></tr>
                <tr><td>Tidevann</td><td>27</td></tr>
                <tr><td>Cashout Cavaliers</td><td>16</td></tr>
                
              </tbody>
            </table>
          </div>

          <div className="signup-container">
            {paymentSuccess ? (
              <div className="payment-success">
                <h2 className="successful">Signup Successful!</h2>
                <p>Thank you for signing up your team: <strong>{formData.teamName}</strong>.</p>
                <p>Captain: <strong>{formData.captainName}</strong></p>
                <p>Team Link: <a href={formData.teamLink} target="_blank" rel="noopener noreferrer">{formData.teamLink}</a></p>
              </div>
            ) : (
              <CheckoutForm
                formData={formData}
                setFormData={setFormData}
                setError={setError}
                setProcessing={setProcessing}
                setPaymentSuccess={setPaymentSuccess}
              />
            )}
          </div>
          </div>

          
        </div>
      </Elements>
    );
  }

  export default CashoutCup;