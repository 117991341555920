import React, { useState, useEffect, useCallback } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import './store.css';
import JerseyBack from "./backfinalCC.png";
import JerseyFront from "./fulljerseyfinalCC.png";
import CloseUpLogo from "./closeupFinal.png";
import { logPageView } from '../../analytics';


// Replace with your actual Stripe publishable key
const stripePromise = loadStripe('pk_live_51PBydeIitOy6POD7I5OZ0HRyiem5lj1BeDsnqdVuiKGlLCZZeKhOocuYL8M9ErDpXzWCd9m1hUxPpt8jdhVQQnCo00d7OYIqkH');

const CheckoutForm = ({ itemAmount, jerseySize, onBack }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [formData, setFormData] = useState({
    email: '',
    name: '',
    line1: '',
    line2: '',
    city: '',
    state: '',
    postal_code: '',
    country: 'NO'
  });

  const [fontSize, setFontSize] = useState('16px');

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 480) {
        setFontSize('10px');
      } else if (window.innerWidth <= 768) {
        setFontSize('14px');
      } else {
        setFontSize('16px');
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Call once to set initial size

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const cardElementOptions = {
    style: {
      base: {
        fontSize: fontSize,
        color: '#32325d',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    },
  };



  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setProcessing(true);
    setError(null);
    setPaymentSuccess(false);

    if (!stripe || !elements) {
      return;
    }

    const response = await fetch('https://cashoutcavaliersbackend-9954216257f6.herokuapp.com/paymentRoutes/createPaymentIntent', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        amount: 299 * 100 * itemAmount + 6900,
        currency: 'nok',
        jerseySize,
        ...formData,
      }),
    });

    const { clientSecret } = await response.json();

    const result = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name: formData.name,
          email: formData.email,
          address: {
            line1: formData.line1,
            line2: formData.line2,
            city: formData.city,
            state: formData.state,
            postal_code: formData.postal_code,
            country: formData.country,
          },
        },
      },
    });

    if (result.error) {
      setError(`Payment failed: ${result.error.message}`);
      setPaymentSuccess(false);
    } else {
      setError(null);
      setPaymentSuccess(true);
      console.log('Payment succeeded!');
      // You might want to trigger any post-payment actions here
    }
    setProcessing(false);
  };

  const totalPrice = 400 * itemAmount + 69;

  if (paymentSuccess) {
    return (
      <div className="payment-success">
        <h2>Payment Successful!</h2>
        <p>Thank you for your purchase of {itemAmount} jersey(s) (Size: {jerseySize}).</p>
        <p>Total amount paid: {totalPrice.toFixed(2)} NOK</p>
        
        <button onClick={onBack}>Return to Store</button>
      </div>
    );
  }

  return (
    <div className="checkout-container">
      <div className="checkout-summary">
        <h2>Cashout Cavaliers 2024 Official Jersey</h2>
        <div className="price">{totalPrice.toFixed(2)} NOK</div>
        <p>One-time purchase</p>
        <div className="item">
          <span>Jersey ({jerseySize}) x {itemAmount}</span>
          <span>{(400 * itemAmount).toFixed(2)} NOK</span>
        </div>
        <div className="subtotal">
          <span>Shipping</span>
          <span>69.00 NOK</span>
        </div>
        <div className="total">
          <span>Total due today</span>
          <span>{totalPrice.toFixed(2)} NOK</span>
        </div>
      </div>
      <div className="checkout-form">
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} required />
          </div>
          <div className="form-group">
            <label htmlFor="name">Full Name</label>
            <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} required />
          </div>
          <div className="form-group">
            <label htmlFor="line1">Address Line 1</label>
            <input type="text" id="line1" name="line1" value={formData.line1} onChange={handleChange} required />
          </div>
          <div className="form-group">
            <label htmlFor="line2">Address Line 2</label>
            <input type="text" id="line2" name="line2" value={formData.line2} onChange={handleChange} />
          </div>
          <div className="form-group">
            <label htmlFor="city">City</label>
            <input type="text" id="city" name="city" value={formData.city} onChange={handleChange} required />
          </div>
          <div className="form-group">
            <label htmlFor="state">State/Province</label>
            <input type="text" id="state" name="state" value={formData.state} onChange={handleChange} />
          </div>
          <div className="form-group">
            <label htmlFor="postal_code">Postal Code</label>
            <input type="text" id="postal_code" name="postal_code" value={formData.postal_code} onChange={handleChange} required />
          </div>
          <div className="form-group">
            <label htmlFor="country">Country</label>
            <select id="country" name="country" value={formData.country} onChange={handleChange} required>
              <option value="NO">Norway</option>
              {/* Add more countries as needed */}
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="card">Card information</label>
            <CardElement id="card" options={cardElementOptions}/>
          </div>
          {error && <div className="error">{error}</div>}
          <button type="submit" className="subscribe-button" disabled={!stripe || processing}>
            {processing ? 'Processing...' : `Pay ${totalPrice.toFixed(2)} NOK`}
          </button>
          <button type="button" onClick={onBack} className="back-button">
            Back
          </button>
        </form>
      </div>
    </div>
  );
};

const Store = () => {
  const [itemAmount, setItemAmount] = useState(1);
  const [showCheckout, setShowCheckout] = useState(false);
  const [imageSource, setImageSource] = useState(JerseyFront);
  const [jerseySize, setJerseySize] = useState('M');

  useEffect(() => {
    logPageView();
  }, []);


  const changeImage = useCallback((image) => {
    setImageSource(image);
  }, []);

  const decreaseAmount = useCallback(() => {
    setItemAmount(prevAmount => Math.max(1, prevAmount - 1));
  }, []);

  const increaseAmount = useCallback(() => {
    setItemAmount(prevAmount => prevAmount + 1);
  }, []);

  const handleCheckout = useCallback(() => {
    setShowCheckout(true);
  }, []);

  const handleBackToStore = useCallback(() => {
    setShowCheckout(false);
  }, []);

  const handleSizeChange = useCallback((e) => {
    setJerseySize(e.target.value);
  }, []);

  return (
    <div className="store-page">
      {!showCheckout ? (
        <div className="jersey-holder">
          <div className='store-miniature-image-holder'>
            <img src={JerseyFront} alt="Jersey Front" className="store-miniature-image" onClick={() => changeImage(JerseyFront)}/>
            <img src={CloseUpLogo} alt="Close Up Logo" className="store-miniature-image" onClick={() => changeImage(CloseUpLogo)}/>
            <img src={JerseyBack} alt="Jersey Back" className="store-miniature-image" onClick={() => changeImage(JerseyBack)}/>
          </div>
          <div className="jersey-image-placeholder">
            <img src={imageSource} alt="Jersey Front" className="jersey-image"/>
          </div>
          <div className="jersey-information">
            <h2 className="jersey-h2">Cashout Cavaliers 2024 Official Jersey</h2>
            <p className="jersey-p">299 NOK + 69kr shipping</p>
            <div className="size-holder">
              <p className="size-p">Size:</p>
              <select className="size-select" value={jerseySize} onChange={handleSizeChange}>
                <option value="M">M</option>
                <option value="L">L</option>
              </select>
            </div>
            <p className='amount-p'>Amount:</p>
            <div className="items-amount">
              <button id="minus-button" onClick={decreaseAmount}>-</button>
              <p>{itemAmount}</p>
              <button onClick={increaseAmount}>+</button>
            </div>
            <button className="check-out" onClick={handleCheckout}>Check out</button>
          </div>
        </div>
      ) : (
        <div className="stripe-checkout">
          <Elements stripe={stripePromise}>
            <CheckoutForm 
              itemAmount={itemAmount}
              jerseySize={jerseySize}
              onBack={handleBackToStore}
            />
          </Elements>
        </div>
      )}
    </div>
  );
};

export default Store;