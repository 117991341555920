import React, { useState, useRef, useEffect, useCallback } from 'react';
import './advertising.css';
import { loadStripe} from '@stripe/stripe-js';
import { Elements, useStripe, useElements, CardElement  } from '@stripe/react-stripe-js';
const stripePromise = loadStripe('pk_live_51PBydeIitOy6POD7I5OZ0HRyiem5lj1BeDsnqdVuiKGlLCZZeKhOocuYL8M9ErDpXzWCd9m1hUxPpt8jdhVQQnCo00d7OYIqkH');

const CheckoutForm = ({ itemAmount,selectedAds, onBack }) => {
    const stripe = useStripe();
    const elements = useElements();
    const [error, setError] = useState(null);
    const [processing, setProcessing] = useState(false);
    const [paymentSuccess, setPaymentSuccess] = useState(false);
    const [formData, setFormData] = useState({

      country: 'NO'
    });
    
  
    const [fontSize, setFontSize] = useState('16px');
    const totalPrice = selectedAds.reduce((total, ad) => {
        return total + Number(ad.price);
      }, 0); // Starting valu
  console.log(totalPrice);
    useEffect(() => {
      const handleResize = () => {
        if (window.innerWidth <= 480) {
          setFontSize('10px');
        } else if (window.innerWidth <= 768) {
          setFontSize('14px');
        } else {
          setFontSize('16px');
        }
      };
  
      window.addEventListener('resize', handleResize);
      handleResize(); // Call once to set initial size
  
      return () => window.removeEventListener('resize', handleResize);
    }, []);
  
    const cardElementOptions = {
      style: {
        base: {
          fontSize: fontSize,
          color: '#32325d',
          fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
          fontSmoothing: 'antialiased',
          '::placeholder': {
            color: '#aab7c4',
          },
        },
        invalid: {
          color: '#fa755a',
          iconColor: '#fa755a',
        },
      },
    };
  
  
  
    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData(prev => ({ ...prev, [name]: value }));
    };
  
    const handleSubmit = async (event) => {
      event.preventDefault();
      setProcessing(true);
      setError(null);
      setPaymentSuccess(false);
  
      if (!stripe || !elements) {
        return;
      }
  
      const response = await fetch('https://cashoutcavaliersbackend-9954216257f6.herokuapp.com/paymentRoutes/createPaymentIntentCashoutCupAds', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          amount: totalPrice * 100, // Stripe expects the price in cents
          currency: 'nok',

        }),
      });
  
      const { clientSecret } = await response.json();
  
      const result = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement)
         
          
        },
      });
  
      if (result.error) {
        setError(`Payment failed: ${result.error.message}`);
        setPaymentSuccess(false);
      } else {
        setError(null);
        setPaymentSuccess(true);
        console.log('Payment succeeded!');
        // You might want to trigger any post-payment actions here
           // Send files and ad placements to the server
    const formData = new FormData();
    
    // Assuming you have a state that holds the uploaded files, you need to handle them.
    selectedAds.forEach(ad => {
      formData.append('adFiles', ad.file); // Append each uploaded file
      formData.append('adPlacements', ad.placement); // Append each ad placement
    });

    const uploadResponse = await fetch('https://cashoutcavaliersbackend-9954216257f6.herokuapp.com/addCashoutCupAds', {
      method: 'POST',
      body: formData,
    });

    const uploadResult = await uploadResponse.json();
    console.log('Upload result:', uploadResult);
  
    }
      setProcessing(false);
    };
  
   
  
    if (paymentSuccess) {
      return (
        <div className="payment-success">
          <h2>Payment Successful!</h2>
          <p>Thank you for your purchase!</p>
          
          
          <button onClick={onBack}>Return to Store</button>
        </div>
      );
    }
  
    return (
      <div className="checkout-container">
        <div className="checkout-summary">
          <h2>Cashout Cup #1 Advertising</h2>
          <div className="price">{totalPrice} NOK</div>
          <p>One-time purchase</p>


          <div className="total">
            <span>Total due today</span>
            <span>{totalPrice} NOK</span>
          </div>
        </div>
        <div className="checkout-form">
          <form onSubmit={handleSubmit}>
            
              
            <div className="form-group">
              <label htmlFor="card">Card information</label>
              <CardElement id="card" options={cardElementOptions}/>
            </div>
            {error && <div className="error">{error}</div>}
            <button type="submit" className="subscribe-button" disabled={!stripe || processing}>
              {processing ? 'Processing...' : `Pay ${totalPrice} NOK`}
            </button>
            <button type="button" onClick={onBack} className="back-button">
              Back
            </button>
          </form>
        </div>
      </div>
    );
  };

const AdSlotMenu = ({ onConfirm, onClose }) => {
    const [selectedOption, setSelectedOption] = useState('videoLink');
    const [link, setLink] = useState('');
    const [postAdLink, setPostAdLink] = useState(false);
    const [postAdLinkValue, setPostAdLinkValue] = useState('');
    const [uploadedFile, setUploadedFile] = useState(null); // Capture uploaded file
    // Checkout component for handling the stripe payment
const CheckoutButton = ({ selectedAds }) => {
  const stripe = useStripe();
  const elements = useElements();

  const handleCheckout = async () => {
    // Group the ads by price
    const adItems = selectedAds.map(ad => ({
      name: `${ad.slot} ad`,
      description: `Ad in ${ad.placement}`,
      amount: ad.price * 100, // Stripe expects the price in cents
      currency: 'nok',
      quantity: 1,
    }));

    const response = await fetch('/create-checkout-session', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ items: adItems }),
    });

    const session = await response.json();

    // Redirect to Stripe Checkout
    const result = await stripe.redirectToCheckout({
      sessionId: session.id,
    });

    if (result.error) {
      console.error(result.error.message);
    }
  };

  return (
    <button className='checkout-button' onClick={handleCheckout} disabled={!stripe}>
      Check-out
    </button>
  );
};
  
    const handleConfirm = () => {
      onConfirm({
        type: selectedOption,
        link: selectedOption === 'videoLink' ? link : '',
        postAdLink: postAdLink ? postAdLinkValue : null,
        file: uploadedFile, // Send file in confirmation
      });
      onClose();
    };
  
    const handleMenuClick = (e) => {
      e.stopPropagation(); // Prevent bubbling
    };
  
    return (
      <div className="ad-slot-menu" onClick={handleMenuClick}>
        <div>
          <label htmlFor="uploadFile">Upload Ad file</label>
          <input
            type="file"
            id="uploadFile"
            onChange={(e) => {
              setSelectedOption('uploadFile');
              setUploadedFile(e.target.files[0]); // Save the uploaded file
            }}
          />
        </div>
        <div className="ad-bottom-buttons-holder">
          <button onClick={onClose}>Cancel</button>
          <button onClick={handleConfirm}>Confirm Selection</button>
        </div>
      </div>
    );
  };
  
  const AdSlot = ({ slot, price, placement, onSlotConfirm }) => {
    const [showMenu, setShowMenu] = useState(false);
    const [isConfirmed, setIsConfirmed] = useState(false);
    const [adData, setAdData] = useState(null);
  
    const handleClick = () => {
      setShowMenu(!showMenu);
    };
  
    const handleClose = () => {
      setShowMenu(false);
    };
  
    const handleConfirm = (data) => {
      const adInfo = {
        file: data.file,
        price,
        placement,
      };
      setAdData(adInfo);
      setIsConfirmed(true);
      setShowMenu(false);
  
      // Pass the confirmed data to the Advertising component
      onSlotConfirm(adInfo);
    };
  
    return (
      <div className={`individual-ad-slot-holder ${isConfirmed ? 'confirmed' : ''}`} onClick={handleClick}>
        <h3 className="ad-slot-h3">{price},-</h3>
        {showMenu && (
          <div>
            <AdSlotMenu onConfirm={handleConfirm} onClose={handleClose} />
          </div>
        )}
      </div>
    );
  };
  
const Advertising = () => {
  const [singleAd, setSingleAd] = useState(true);
  const [selectedAds, setSelectedAds] = useState([]);
  const [showCheckout, setShowCheckout] = useState(false);

  const handleAdChange = (event) => {
    setSingleAd(event.target.value === 'true');
  }

  const handleBackToStore = useCallback(() => {
    setShowCheckout(false);
  }, []);


  const handleSlotConfirm = (adInfo) => {
    setSelectedAds((prevAds) => [...prevAds, adInfo]); // Append the new ad to the list
  };

  return (

    <div className='advertising-body'>
        {!showCheckout ? (
        <>
      <div className='advertising-header'>
        <h1 className='advertising-header-h1'>Get in on the action!</h1>
        <h2 className='advertising-header-h2'>Advertising in Cashout Cup</h2>
      </div>
      <div className='advertising-info'>
        <h2 className='advertising-info-h2'>How it works</h2>
        <p className='advertising-info-p'>To order an ad slot first click on one of the available green slots. Then fill out the information required
            and repeat until you are satisfied with your selection. Once you are ready to purchase your ad slots complete the transaction 
            by clicking the "Check-out" button and then your ads will be displayed on the Cashout Cashout Cup official stream.
        </p>
      </div>
      

      <div className='advertisement-holder'>
        <div className='slot-length-holder'>
            <h3 className='slot-length-h3'>Select your ad slot length: </h3>
            <select className='slot-length-select' value={singleAd.toString()} onChange={handleAdChange}>
            <option value='true'>30s slot</option>
            <option value='false'>60s slot</option>
            </select>            
        </div>

        <div className='playing-slot'>
          <h3 className='playing-slot-h3'>Pregame show</h3>
        </div>
        <div className='ad-slot-holder'>
        {singleAd ? (
            <>
              <AdSlot slot="30s" price="199" placement="preShow" onSlotConfirm={handleSlotConfirm}/>
              <AdSlot slot="30s" price="199" placement="preShow" onSlotConfirm={handleSlotConfirm}/>
              <AdSlot slot="30s" price="199" placement="preShow" onSlotConfirm={handleSlotConfirm}/>
              <AdSlot slot="30s" price="199" placement="preShow" onSlotConfirm={handleSlotConfirm}/>
              <AdSlot slot="30s" price="199" placement="preShow" onSlotConfirm={handleSlotConfirm}/>
              <AdSlot slot="30s" price="199" placement="preShow" onSlotConfirm={handleSlotConfirm}/>
            </>
          ) : (
            <>
              <AdSlot slot="60s" price="399" placement="preShow" onSlotConfirm={handleSlotConfirm}/>
              <AdSlot slot="60s" price="399" placement="preShow" onSlotConfirm={handleSlotConfirm}/>
              <AdSlot slot="60s" price="399" placement="preShow" onSlotConfirm={handleSlotConfirm}/>
            </>
          )}
        </div>
        <div className='playing-slot'>
          <h3 className='playing-slot-h3'>Top 32</h3>
        </div>
        <div className='ad-slot-holder'>
        {singleAd ? (
            <>
              <AdSlot slot="30s" price="199" placement="top32" onSlotConfirm={handleSlotConfirm}/>
              <AdSlot slot="30s" price="199" placement="top32" onSlotConfirm={handleSlotConfirm}/>
              <AdSlot slot="30s" price="199" placement="top32" onSlotConfirm={handleSlotConfirm}/>
              <AdSlot slot="30s" price="199" placement="top32" onSlotConfirm={handleSlotConfirm}/>
              <AdSlot slot="30s" price="199" placement="top32" onSlotConfirm={handleSlotConfirm}/>
              <AdSlot slot="30s" price="199" placement="top32" onSlotConfirm={handleSlotConfirm}/>
            </>
          ) : (
            <>
              <AdSlot slot="60s" price="399" placement="top32" onSlotConfirm={handleSlotConfirm}/>
              <AdSlot slot="60s" price="399" placement="top32" onSlotConfirm={handleSlotConfirm}/>
              <AdSlot slot="60s" price="399" placement="top32" onSlotConfirm={handleSlotConfirm}/>
            </>
          )}
        </div>
        <div className='playing-slot'>
          <h3 className='playing-slot-h3'>Top 16</h3>
        </div>
        <div className='ad-slot-holder'>
        {singleAd ? (
            <>
              <AdSlot slot="30s" price="199" placement="top16" onSlotConfirm={handleSlotConfirm}/>
              <AdSlot slot="30s" price="199" placement="top16" onSlotConfirm={handleSlotConfirm}/>
              <AdSlot slot="30s" price="199" placement="top16" onSlotConfirm={handleSlotConfirm}/>
              <AdSlot slot="30s" price="199" placement="top16" onSlotConfirm={handleSlotConfirm}/>
              <AdSlot slot="30s" price="199" placement="top16" onSlotConfirm={handleSlotConfirm}/>
              <AdSlot slot="30s" price="199" placement="top16" onSlotConfirm={handleSlotConfirm}/>
            </>
          ) : (
            <>
              <AdSlot slot="60s" price="399" placement="top16" onSlotConfirm={handleSlotConfirm}/>
              <AdSlot slot="60s" price="399" placement="top16" onSlotConfirm={handleSlotConfirm}/>
              <AdSlot slot="60s" price="399" placement="top16" onSlotConfirm={handleSlotConfirm}/>
            </>
          )}
        </div>
        <div className='playing-slot'>
          <h3 className='playing-slot-h3'>Quarterfinals</h3>
        </div>
        <div className='ad-slot-holder'>
        {singleAd ? (
            <>
              <AdSlot slot="30s" price="199" placement="top8" onSlotConfirm={handleSlotConfirm}/>
              <AdSlot slot="30s" price="199" placement="top8" onSlotConfirm={handleSlotConfirm}/>
              <AdSlot slot="30s" price="199" placement="top8" onSlotConfirm={handleSlotConfirm}/>
              <AdSlot slot="30s" price="199" placement="top8" onSlotConfirm={handleSlotConfirm}/>
              <AdSlot slot="30s" price="199" placement="top8" onSlotConfirm={handleSlotConfirm}/>
              <AdSlot slot="30s" price="199" placement="top8" onSlotConfirm={handleSlotConfirm}/>
              
            </>
          ) : (
            <>
              <AdSlot slot="60s" price="399" placement="top8" onSlotConfirm={handleSlotConfirm}/>
              <AdSlot slot="60s" price="399" placement="top8" onSlotConfirm={handleSlotConfirm}/>
              <AdSlot slot="60s" price="399" placement="top8" onSlotConfirm={handleSlotConfirm}/>
            </>
          )}
        </div>
        <div className='playing-slot'>
          <h3 className='playing-slot-h3'>Semifinals</h3>
        </div>
        <div className='ad-slot-holder'>
        {singleAd ? (
            <>
              <AdSlot slot="30s" price="249" placement="top4" onSlotConfirm={handleSlotConfirm}/>
              <AdSlot slot="30s" price="249" placement="top4" onSlotConfirm={handleSlotConfirm}/>
              <AdSlot slot="30s" price="249" placement="top4" onSlotConfirm={handleSlotConfirm}/>
              <AdSlot slot="30s" price="249" placement="top4" onSlotConfirm={handleSlotConfirm}/>
              <AdSlot slot="30s" price="249" placement="top4" onSlotConfirm={handleSlotConfirm}/>
              <AdSlot slot="30s" price="249" placement="top4" onSlotConfirm={handleSlotConfirm}/>
            </>
          ) : (
            <>
              <AdSlot slot="60s" price="499" placement="top4" onSlotConfirm={handleSlotConfirm}/>
              <AdSlot slot="60s" price="499" placement="top4" onSlotConfirm={handleSlotConfirm}/>
              <AdSlot slot="60s" price="499" placement="top4" onSlotConfirm={handleSlotConfirm}/>
            </>
          )}
        </div>
        <div className='playing-slot'>
          <h3 className='playing-slot-h3'>Finals</h3>
        </div>
        <div className='ad-slot-holder'>
        {singleAd ? (
            <>
              <AdSlot slot="30s" price="249" placement="top2" onSlotConfirm={handleSlotConfirm}/>
              <AdSlot slot="30s" price="249" placement="top2" onSlotConfirm={handleSlotConfirm}/>
              <AdSlot slot="30s" price="249" placement="top2" onSlotConfirm={handleSlotConfirm}/>
              <AdSlot slot="30s" price="249" placement="top2" onSlotConfirm={handleSlotConfirm}/>
              <AdSlot slot="30s" price="249" placement="top2" onSlotConfirm={handleSlotConfirm}/>
              <AdSlot slot="30s" price="249" placement="top2" onSlotConfirm={handleSlotConfirm}/>
            </>
          ) : (
            <>
              <AdSlot slot="60s" price="499" placement="top2" onSlotConfirm={handleSlotConfirm}/>
              <AdSlot slot="60s" price="499" placement="top2" onSlotConfirm={handleSlotConfirm}/>
              <AdSlot slot="60s" price="499" placement="top2" onSlotConfirm={handleSlotConfirm}/>
            </>
          )}
        </div>
        <button className='checkout-button' onClick={() => setShowCheckout(true)} disabled={selectedAds.length === 0}>Checkout</button>
      </div>
      </>
        ) : (         
        <div className="stripe-checkout">
            <Elements stripe={stripePromise}>
              <CheckoutForm 
                selectedAds={selectedAds}
                onBack={handleBackToStore}
              />
            </Elements>
          </div>
        )}
    </div>
  );
}

export default Advertising;