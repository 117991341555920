import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/home/home';
import Store from './pages/store/store';
import AboutUs from './pages/aboutUs/aboutUs';
import CashoutCup from './pages/cashoutCup/cashoutCup';
import SaturdayShowdown from './pages/saturdayShowdown/saturdayShowdown';
import Advertising from './pages/advertising/advertising';
import { initGA, logPageView } from './analytics';
import CashoutLogo from "./cashoutLogo.svg";
import './App.css';

function App() {
  useEffect(() => {
    initGA('G-TEP59K5QXD');
    logPageView();
  }, []);

  return (
    <Router>
      <nav className='cashout-nav'>
        <div className="nav-content">
          <a href="/" className="logo-link">
            <img src={CashoutLogo} alt="Cashout Cavaliers Logo" className='logo'/>
          </a>
          <a href="/about-us" className="nav-item">ABOUT US</a>
          <a href="/store" className="nav-item">STORE</a>
          <a href="/cashoutcup" className='nav-item'>CASHOUT CUP</a>
          <a href="saturdayshowdown" className='nav-item'>SATURDAY SHOWDOWN</a>
        </div>
      </nav>

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/store" element={<Store />} />
        <Route path="/cashoutcup" element={<CashoutCup />} />
        <Route path="/advertising" element={<Advertising />} />
        <Route path="/saturdayshowdown" element={<SaturdayShowdown />} />
      </Routes>

      <footer className='cashout-footer'>
        <p>© 2024 Cashout Cavaliers</p>
      </footer>
    </Router>
  );
}

export default App;