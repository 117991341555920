import React from "react";
import BergenGames from "./bergenGames.jpg";
import ESEA from "./esea.jpg";
import GoodGameLigaen from "./goodgameliga.png";

import './aboutUs.css';

const AboutUs = () => {
    return (
        <div className="about-us-container">
            <div className="about-us-head-container">
                <div className="fresh-esports-outlook">
                    <h1 className="about-us-h1">A <span id="fresh">FRESH</span></h1>
                    <h3 className="about-us-h3">Outlook on Esports</h3>
                </div>
                <div className="start-dreaming-again">
                    <h1 className="about-us-h1">START</h1>
                    <h3 className="about-us-h3"> Dreaming Again</h3>
                </div>
            </div>
            <div className="learning-from-mistakes-holder">
                <div className="about-us-text-holder">
                <h2 className="about-us-h2">Learning from other's mistakes</h2>
                <p className="about-us-p">We have always been passionate about esports. We love that it has grown from a pong tournament to a global phenomenon that enriches the lives of so many.
                </p>
                <p className="about-us-p">However, that growth has come at a price. The influx of cheap venture capital money in the 2010s made it easy for teams and organizers to close their eyes to the financial health and sustainability of the orgs and the esports environment in general.This has led to an ecosystem built on unsustainable principles which hurt both the fans and the players in the long term.
                </p>
                <p className="about-us-p">
                    We pledge that we will be an organization that prioritizes long term
                    sustainability and creating a lifelong connection with our fans.
                </p>
                </div>
                
            </div>
            <div className="a-journey-to-the-top-holder">
                <div className="about-us-text-holder">

                </div>
                <div className="about-us-text-holder">
                    <h2 className="about-us-h2">A journey to the top</h2>
                    <p className="about-us-p">
                    We do not simply want to compete, we want to win!  We have been 
enthralled by esports through legends like Simple and Faker and
many more.  But times change, and it is time for a new generation
to take center stage.
                    </p>
                    <p className="about-us-p">
                    We believe that esports is more than just playing games and 
making money. For us, the beauty of it all lies in the clashes of will
between athletes going above and beyond chasing their potential
and the resulting moments and memories created in a fair and 
inclusive environment.
                    </p>
                    <p className="about-us-p">
                    We pledge that we will always strive to perform to the best of our
abilities and that we will be a mainstay of the global esports scene
by 2030.
                    </p>
                
                
                </div>

                

            </div>
            <div className="our-achievements-holder">
                    <h2 className="our-achievements-h2">Our Achievements</h2>
                    <div className="achievements-first-row-holder">
                        <div>
                            <img src={BergenGames} alt="Bergen Games" className="achievement-image" id="bergen-games"/>
                            <h4 className="achievement-h4">Main Stage</h4>
                        </div>
                        <div>
                            <img src={GoodGameLigaen} alt="Good Game Ligaen" className="achievement-image" id="ggligaen"/>
                            <h4 className="achievement-h4">2nd Division</h4>
                        </div>

                        
                    </div>
                    <div className="achievements-second-row-holder">
                        <div>
                            <img src={ESEA} alt="ESEA" className="achievement-image"/>
                            <h4 className="achievement-h4">Top 32 ESEA Open</h4>
                        </div>
                        <div>
                            <img src={ESEA} alt="ESEA" className="achievement-image"/>
                            <h4 className="achievement-h4">2nd Season ESEA Intermediate</h4>

                        </div>
                    </div>
                </div>
        </div>
    )
}


export default AboutUs;